import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { Phone } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'

import { Props as LanguageItemProps } from './LanguageItem'
import { Languages } from './Languages'
import { ModalClose } from './ModalClose'
import { Navigation } from './Navigation'
import { Toolbars } from './Toolbars'

export interface Props {
  languagesList: LanguageItemProps[]
  menuItems: MenuItemProps[]
  pageID?: string
}

export const Header = memo(function Header({
  languagesList,
  menuItems,
  pageID,
}: Props) {
  const {
    contactsPageURL,
    email,
    IBEURL,
    languageCode,
    languagePrefix,
    logo,
    logoSticky,
    phone,
    siteName,
    variant,
  } = useContext(HeaderContext) as any

  let beLanguage = ''
  switch (languageCode) {
    case 'de-DE':
      beLanguage = 'deu'
      break
    case 'en-US':
      beLanguage = 'eng'
      break
    case 'es-ES':
      beLanguage = 'esp'
      break
    case 'fr-FR':
      beLanguage = 'fra'
      break

    default:
      beLanguage = 'ita'
      break
  }

  const [scroll, setScroll] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > window.innerHeight)
    }

    disableScroll.off()

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const contactsPageSlug = contactsPageURL?.find(
    (t: any) => t?.languages_code?.code === languageCode,
  )?.slug

  const contactsRealPageURL = languagePrefix
    ? `/${languagePrefix}/${contactsPageSlug}`
    : `/${contactsPageSlug}`

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head
          className={scroll || variant === 'compact' ? 'fixed' : ''}
          dial={4}
          row
          variant={variant}
        >
          <Hamburger
            className="hamburger-menu"
            dial={4}
            onClick={() => {
              if (!modalStatus) {
                setModalStatus(true)
                if (window.innerWidth > 1023) {
                  disableScroll.on()
                } else {
                  document.body.style.overflow = 'hidden'
                }
              } else {
                setModalStatus(false)
              }
            }}
            row
          >
            <HamburgerLines className="hamburger-lines">
              <HamburgerLine />
              <HamburgerLine />
            </HamburgerLines>
            <HambugerLabel>
              {useVocabularyData('menu', languageCode)}
            </HambugerLabel>
          </Hamburger>

          {logo ? (
            <Link to={languagePrefix ? `/${languagePrefix}` : '/'}>
              <Brand className="brand" dial={5}>
                {logoSticky ? (
                  <Media lessThan="desktopSmall">
                    <Logo
                      className="logo"
                      src={logoSticky}
                      alt={siteName}
                      width="28"
                      height="50"
                    />
                  </Media>
                ) : null}

                {logo ? (
                  <Media greaterThanOrEqual="desktopSmall">
                    <Logo
                      className="logo"
                      src={scroll || variant === 'compact' ? logoSticky : logo}
                      alt={siteName}
                      width="47"
                      height="84"
                    />
                  </Media>
                ) : null}
              </Brand>
            </Link>
          ) : null}

          <Languages
            className={
              scroll || variant === 'compact' || variant === 'simple'
                ? 'sticky'
                : undefined
            }
            languagesList={languagesList}
            pageID={pageID}
          />

          {phone ? (
            <Anchor
              className="header-phone"
              aria-label="Telephone"
              href={`tel:${phone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Phone Number',
                    })
                }
              }}
            >
              <Phone />
            </Anchor>
          ) : null}

          {contactsPageURL ? (
            <Button
              className="header-contacts"
              label={useVocabularyData('ask-for', languageCode)}
              URL={contactsRealPageURL}
              variant="invert"
            />
          ) : null}

          {IBEURL || variant === 'compact' ? (
            <Button
              className="header-book"
              label={useVocabularyData('book', languageCode)}
              URL={`${IBEURL}&lingua_int=${beLanguage}`}
              rel="noopener"
              target="_blank"
            />
          ) : null}
        </Head>

        <Modal className={modalStatus ? 'open' : ''}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              if (window.innerWidth > 1023) {
                disableScroll.off()
              } else {
                document.body.style.overflow = 'auto'
              }
              setModalStatus(false)
            }}
          />
          <Navigation
            beLanguage={beLanguage}
            email={email}
            IBEURL={IBEURL}
            languageCode={languageCode}
            menuItems={menuItems}
            modalStatus={modalStatus}
            phone={phone}
          />
        </Modal>

        <Media lessThan="desktopSmall">
          <Toolbars contactsURL={contactsRealPageURL} />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 1.875rem;
  left: 0;
  right: 0;
  z-index: 1000;
`

const HeaderFixedStyle = css`
  background: ${theme.colors.variants.neutralLight4};
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 60px;
  padding-right: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  .hamburger-menu {
    margin-top: 0;
    span {
      color: ${theme.colors.variants.primaryDark2};
    }
    .hamburger-lines {
      div {
        background: ${theme.colors.variants.primaryDark2};
      }
    }
  }

  .brand {
    width: 3.125rem;
    height: calc(100% - 1.25rem);
    background: transparent;
    top: 0.625rem;
    img {
      height: 100%;
    }
  }

  .header-phone {
    width: 4.375rem;
    height: 4.375rem;
    background: ${theme.colors.variants.neutralLight3};
    &:hover {
      background: ${theme.colors.variants.primaryDark2};
      svg {
        fill: ${theme.colors.variants.neutralLight4};
      }
    }
    svg {
      fill: ${theme.colors.variants.primaryDark2};
    }
  }

  .header-contacts {
    min-width: 10rem;
    height: 4.375rem;
    background: ${theme.colors.variants.neutralLight2};
    border-color: transparent;
    color: ${theme.colors.variants.primaryDark2};
    margin-right: 0;
    transition: background 0.2s ease-in-out;
    &:hover {
      background: ${theme.colors.variants.neutralLight1};
      border-color: transparent;
      color: ${theme.colors.variants.neutralLight4};
    }
  }

  .header-book {
    min-width: 10rem;
    height: 4.375rem;
  }

  @media (max-width: 1199px) {
    .header-phone,
    .header-contacts {
      display: none;
    }
    .header-book {
      line-height: 4.375rem;
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    .header-book {
      display: none;
    }
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  height: 7.5rem;
  padding: 0 4.5rem;
  position: relative;
  &.fixed {
    height: 4.375rem;
    animation: fadeInTop 0.3s;

    ${HeaderFixedStyle}
  }

  .header-contacts {
    margin-right: 0.75rem;
  }

  @media (max-width: 1199px) {
    height: auto;
    animation: none !important;
    padding-left: 0;

    ${HeaderFixedStyle}
  }

  @keyframes fadeInTop {
    0% {
      top: -5rem;
    }
    100% {
      top: 0;
    }
  }
`

const Hamburger = styled(FlexBox)`
  cursor: pointer;
  margin-right: auto;
  position: relative;
  transition: 0.3s ease-in-out;
  &:hover {
    .hamburger-lines {
      div {
        &:nth-of-type(2) {
          width: 60%;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    margin-left: 1.875rem;
  }

  @media (max-width: 767px) {
    margin-left: 1.25rem;
  }
`

const HamburgerLines = styled.div`
  width: 2.25rem;
`

const HamburgerLine = styled.div`
  width: 100%;
  height: 0.125rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 1.25rem;
  margin: 0.625rem 0;
  transition: 0.3s ease-in-out;
`

const HambugerLabel = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.125rem;
  margin-left: 1.3125rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Brand = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  margin: auto;
  width: 7.5rem;
  height: 7.5rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`

const Logo = styled.img`
  width: auto;
  height: 5.25rem;
`

const Anchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  padding: 0 1.5rem;
  position: relative;
  transition: background 0.2s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
  &:before {
    content: '';
    width: 0.0625rem;
    height: 1.875rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.4;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    transition: 0.2s ease-in-out;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 1000;
  }
`

interface HeadProps {
  variant: Variant
}

export type Variant = 'default' | 'compact' | 'noibe'
